<template>
    <div>
      <h2>Sidebar</h2>
      <!-- Add your sidebar structure here -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'SideBar',
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  